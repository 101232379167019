<template>
  <div class="reveal">
    <div class="locale-switcher">
      <el-radio-group v-model="$i18n.locale" @change="changeLocale">
        <el-radio-button label="ru">RU</el-radio-button>
        <el-radio-button label="en">EN</el-radio-button>
      </el-radio-group>
    </div>
    <div class="slides">
      <!-- Intro -->
      <section :data-background-image="require('@/assets/presentation/background1.jpg')">
        <div class="intro-slide">
          <img :src="require('@/assets/presentation/logo-white.png')" alt="inFibo">
          <p>FALL INTO PLACE</p>
        </div>
      </section>
      <!-- What is inFibo? -->
      <section :data-background-image="require('@/assets/presentation/background2.png')">
        <p>{{ $t('whatIsInfibo') }}</p>
        <div class="left-border">
          <p>{{ $t('whatIsInfiboThesis1') }}</p>
          <p>{{ $t('whatIsInfiboThesis2') }}</p>
        </div>
      </section>
      <!-- About forex -->
      <section :data-background-image="require('@/assets/presentation/background2.png')">
        <div>
          <p>{{ $t('aboutForex') }}</p>
          <hr style="width: 250px">
          <div class="d-inline-flex justify-content-between">
            <img :src="require('@/assets/presentation/about-forex.png')" alt="About forex"
                 style="height: 485px; width: 485px;">
            <div style="font-size: 18px; text-align: left; margin-left: 50px">
              <p>{{ $t('aboutForexThesis1') }}</p>
              <p>{{ $t('aboutForexThesis2') }}</p>
              <p>{{ $t('aboutForexThesis3') }}</p>
            </div>
          </div>
        </div>
      </section>
      <!-- Market scheme -->
      <section :data-background-image="require('@/assets/presentation/background2.png')">
        <p>{{ $t('marketParticipants') }}</p>
        <hr style="width: 250px">
        <div class="mt-4">
          <img v-if="currentLocale === 'ru'" :src="require('@/assets/presentation/market-participants-ru.png')"
               alt="">
          <img v-else :src="require('@/assets/presentation/market-participants-en.png')" alt="">
        </div>
      </section>
      <!-- How infibo works -->
      <section :data-background-image="require('@/assets/presentation/background2.png')">
        <div class="d-flex flex-column align-items-start">
          <p class="m-0">{{ $t('infiboSystem') }}</p>
          <p class="m-0" style="font-size: 26px; font-style: italic">{{ $t('howItWorks') }}</p>
        </div>
        <div class="d-inline-flex">
          <div class="col-6">
            <img v-if="currentLocale === 'ru'" :src="require('@/assets/presentation/infibo-system-scheme-ru.png')"
                 alt="">
            <img v-else :src="require('@/assets/presentation/infibo-system-scheme-en.png')" alt="">
          </div>
          <div class="col-6 text-left" style="font-size: 16px">
            <p class="m-0">{{ $t('hIWStep1') }}</p>
            <ul>
              <li>{{ $t('hIWStep11') }}</li>
              <li>{{ $t('hIWStep12') }}</li>
              <li>{{ $t('hIWStep13') }}</li>
              <li>{{ $t('hIWStep14') }}</li>
            </ul>
            <p>{{ $t('hIWStep2') }}</p>
            <p>{{ $t('hIWStep3') }}</p>
            <p>{{ $t('hIWStep4') }}</p>
          </div>
        </div>
      </section>
      <!-- System components -->
      <section :data-background-image="require('@/assets/presentation/background2.png')">
        <p>{{ $t('systemComponents') }}</p>
        <div class="mt-4">
          <img :src="require('@/assets/presentation/infibo-system-components.png')" alt="" width="800px">
        </div>
      </section>
      <section :data-background-image="require('@/assets/presentation/background2.png')">
        <p>{{ $t('systemComponents') }}</p>
        <div class="text-left" style="font-size: 20px">
          <p>{{ $t('systemComponent1') }}</p>
          <p>{{ $t('systemComponent2') }}</p>
          <p>{{ $t('systemComponent3') }}</p>
          <p>{{ $t('systemComponent4') }}</p>
        </div>

      </section>
      <!-- How to subscribe -->
      <section :data-background-image="require('@/assets/presentation/background2.png')">
        <p>{{ $t('howToSubscribe') }}</p>
        <hr style="width: 250px">
      </section>
      <section data-background-size="contain" data-background-color="black"
               :data-background-image="currentLocale === 'ru' ? require('@/assets/presentation/infibo-how-to-subscribe-ru-1.png') : require('@/assets/presentation/infibo-how-to-subscribe-en-1.png')"></section>
      <section data-background-size="contain" data-background-color="black"
               :data-background-image="currentLocale === 'ru' ? require('@/assets/presentation/infibo-how-to-subscribe-ru-2.png') : require('@/assets/presentation/infibo-how-to-subscribe-en-2.png')"></section>
      <!-- How to buy a license -->
      <section :data-background-image="require('@/assets/presentation/background2.png')">
        <p>{{ $t('howToBuyLicense') }}</p>
        <hr style="width: 250px">
      </section>
      <section data-background-size="contain"
               :data-background-image="currentLocale === 'ru' ? require('@/assets/presentation/infibo-how-to-buy-a-license-ru-1.png') : require('@/assets/presentation/infibo-how-to-buy-a-license-en-1.png')"></section>
      <section data-background-size="contain"
               :data-background-image="currentLocale === 'ru' ? require('@/assets/presentation/infibo-how-to-buy-a-license-ru-2.png') : require('@/assets/presentation/infibo-how-to-buy-a-license-en-2.png')"></section>
      <!-- Screenshots -->
      <section :data-background-image="require('@/assets/presentation/background2.png')">
        <p class="mb-0">{{ $t('infiboSite') }}</p>
        <p class="m-0" style="font-size: 26px;">{{ $t('onTheNextSlides') }}</p>
        <div class="d-inline-flex justify-content-between">
          <div class="flex-column screenshot-link"
               @click="goToSlide(1, true)">
            <img
                :src="currentLocale === 'ru' ? require('@/assets/presentation/infibo-review-screenshot-ru.png') : require('@/assets/presentation/infibo-review-screenshot-en.png')"
                alt=""
                width="300px">
            <p class="m-0 text" style="font-size: 22px;">{{ $t('review') }}</p>
          </div>
          <div class="flex-column screenshot-link"
               @click="goToSlide(2, true)">
            <img
                :src="currentLocale === 'ru' ? require('@/assets/presentation/infibo-trades-screenshot-ru.png') : require('@/assets/presentation/infibo-trades-screenshot-en.png')"
                alt=""
                width="300px">
            <p class="m-0 text" style="font-size: 22px;">{{ $t('tradeHistory') }}</p>
          </div>
          <div class="flex-column screenshot-link"
               @click="goToSlide(3, true)">
            <img
                :src="currentLocale === 'ru' ? require('@/assets/presentation/infibo-faq-screenshot-ru.png') : require('@/assets/presentation/infibo-faq-screenshot-en.png')"
                alt=""
                width="300px">
            <p class="m-0 text" style="font-size: 22px;">{{ $t('faqPage') }}</p>
          </div>
        </div>
      </section>
      <!-- Review page screenshot -->
      <section data-background-size="contain"
               :data-background-image="currentLocale === 'ru' ? require('@/assets/presentation/infibo-review-screenshot-ru.png') : require('@/assets/presentation/infibo-review-screenshot-en.png')"></section>
      <!-- Trades page screenshot -->
      <section data-background-size="contain"
               :data-background-image="currentLocale === 'ru' ? require('@/assets/presentation/infibo-trades-screenshot-ru.png') : require('@/assets/presentation/infibo-trades-screenshot-en.png')"></section>
      <!-- FAQ page screenshot -->
      <section data-background-size="contain"
               :data-background-image="currentLocale === 'ru' ? require('@/assets/presentation/infibo-faq-screenshot-ru.png') : require('@/assets/presentation/infibo-faq-screenshot-en.png')"></section>
      <!-- Final -->
      <section :data-background-image="require('@/assets/presentation/background1.jpg')">
        <div class="intro-slide">
          <img :src="require('@/assets/presentation/logo-white.png')" alt="inFibo">
          <p><a href="/">{{ $t('returnToInfibo') }}</a></p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Reveal from 'reveal.js'
import {changeLocale} from '@/plugins/i18n'

export default {
    name: 'Presentation',
    i18n: {
        messages: {
            ru: {
                title: 'inFibo - Презентация',
                whatIsInfibo: 'ЧТО ТАКОЕ INFIBO',
                whatIsInfiboThesis1: 'Нейросетевая технология прогнозирования поведения международных финансовых рынков',
                whatIsInfiboThesis2: 'Автоматизированная система доставки сигналов конечным пользователям',
                aboutForex: 'ПАРА СЛОВ О FOREX',
                aboutForexThesis1: 'Форекс – это международный финансовый рынок. В качестве товара здесь выступают валюты. Вполне приемлемо сравнение этого рынка с глобальным обменным пунктом, в котором одна денежная единица покупается (или продается) за другую.',
                aboutForexThesis2: 'Forex предоставляет возможность получения прибыли благодаря курсовой разнице. При падении спроса на любую из валют, она дешевеет. Поэтому, другая денежная единица начинает пользоваться большим спросом, и ее цена растет. Зарабатывать можно как на падении цен, так и на их росте.',
                aboutForexThesis3: 'Трейдинг может осуществляется через интернет или с помощью телефона. Причем это можно делать круглосуточно, за исключением выходных и праздничных дней.  Ежедневный объем торгов достигает 10 трлн. долларов США. Очевидно, это самый большой рынок в мире.',
                marketParticipants: 'УЧАСТНИКИ РЫНКА',
                infiboSystem: 'СИСТЕМА inFIBO',
                howItWorks: 'как это работает',
                hIWStep1: 'Если у вас еще нет своего брокера:',
                hIWStep11: 'выберите брокера;',
                hIWStep12: 'зайдите на его сайт (например: www.forex.com);',
                hIWStep13: 'откройте счет;',
                hIWStep14: 'пополните его денежными средствами.',
                hIWStep2: 'Зайдите на сайт www.infibo.com, подпишитесь на сервис inFIBO. В этот момент вам будет автоматически выделена виртуальная машина с предустановленой системой Windows, терминалом вашего брокера и программой inFiboClient.',
                hIWStep3: 'Начиная с этого момента система ежесекундно оценивает возможность открытия сделок и вероятность достижения прибыли. Программа inFiboClient будет получать соответствующие сигналы от системы.',
                hIWStep4: 'Вы можете видеть каждую транзакцию и состояние вашего счета в реальном времени в вашем личном кабинете на сайте www.infibo.com. Информация на сайте обновляется каждые 2 секунды.',
                systemComponents: 'КОМПОНЕНТЫ СИСТЕМЫ',
                systemComponent1: 'Luka Cloud - представляет собой пакет программ (торговых советников), каждая из которых опирается на уникальную комбинацию технических и фундаментальных индикаторов. Эти программы обладают свойством самообучения и организованы по принципу искусственных нейронных сетей. Они ежесекундно оценивают вероятность успеха (достижения прибыли) в случае открытия позиции. Торговые советники передают информацию надстройке, которая, оценив совокупность поступивших данных, вырабатывает торговые сигналы и записывает их базу данных.',
                systemComponent2: 'Keeper Cloud - получает торговые рекомендации от Luka Cloud и оценивает риски в соответсвии с параметрами каждого счета. На основе этих оценок принимаются решения об открытии или закрытии позиций.',
                systemComponent3: 'inFibo Cloud – веб сайт и база данных. Предоставляет доступ к личным кабинетам подписчиков.',
                systemComponent4: 'Terminals Cloud – содержит торговые терминалы и установленные на них приложения inFiboClient.',
                systemComponent5: 'inFiboOfficeCloud – рабочие места разработчиков и аналитиков.',
                howToSubscribe: 'КАК ПОДПИСАТЬСЯ',
                howToBuyLicense: 'КАК КУПИТЬ ЛИЦЕНЗИЮ',
                infiboSite: 'САЙТ WWW.INFIBO.COM',
                review: 'Обзор',
                tradeHistory: 'История торговли',
                faqPage: 'Часто задаваемые вопросы',
                onTheNextSlides: 'на последующих слайдах показаны:',
                returnToInfibo: 'ВЕРНУТЬСЯ К INFIBO.COM',
            },
            en: {
                title: 'inFibo - Презентация',
                whatIsInfibo: 'WHAT IS INFIBO',
                whatIsInfiboThesis1: 'Neural network technology for forecasting the behavior of international financial markets',
                whatIsInfiboThesis2: 'Automated signals delivery system to end users',
                aboutForex: 'A FEW WORDS ABOUT FOREX',
                aboutForexThesis1: 'FOREX is an international financial market. As the goods here are currencies. It is quite acceptable to compare this market with a global exchange office in which one currency is bought (or sold) for another.',
                aboutForexThesis2: 'Forex provides the opportunity to make money from exchange rate differences. If demand falls on any of the currencies, it becomes cheaper. Therefore, another currency unit begins to be in great demand, and its price is growing. You can earn both on the fall in prices, and on their growth.',
                aboutForexThesis3: 'Trading can be done via internet or by phone 24 hours a day five days a week exclude weekends and holidays. The daily trading volume reaches 10 trillion US dollars. Obviously, this is the largest market in the world.',
                marketParticipants: 'PARTICIPANTS OF THE MARKET',
                infiboSystem: 'inFIBO system',
                howItWorks: 'how it works',
                hIWStep1: 'If you don\'t have a broker:',
                hIWStep11: 'choose а broker;',
                hIWStep12: 'go to the broker\'s website (for example: www.forex.com);',
                hIWStep13: 'open an account;',
                hIWStep14: 'deposit funds into your account.',
                hIWStep2: 'Go to www.infibo.com, subscribe to inFIBO service. At this point, you will automatically be allocated a virtual machine with a preinstalled Windows system, your broker\'s terminal and the inFiboClient program.',
                hIWStep3: 'Starting from this moment, the system every second assesses the possibility of opening deals and the probability of achieving a profit. The inFiboClient program will receive the appropriate signals from the system.',
                hIWStep4: 'You can see every transaction and the state of your account in real time in your dashboard on the website of infibo.com. www.infibo.com. The information on the site is updated every 2 seconds.',
                systemComponents: 'COMPONENTS OF THE SYSTEM',
                systemComponent1: 'Luka Cloud is a package of programs (expert advisors), each of which is based on a unique combination of technical and fundamental indicators. These advisers have a possibility of self-training and organized on the principle of artificial neural networks. Every second they assess the likelihood of success (achieving profit) in case of opening a position. Trading advisors transmit information to a superstructure, which, considering the totality of the data arrived, generates trading signals and write them into database.',
                systemComponent2: 'Keeper Cloud - receives trading recommendations from Luka Cloud and assesses risks in accordance with the parameters of each account. Based on these assessments, decisions are made to open or close a positions.',
                systemComponent3: 'inFibo Cloud – website and database. Provides access to personal dashboards of subscribers.',
                systemComponent4: 'Terminals Cloud – contains trading terminals and inFiboClient applications installed on them.',
                systemComponent5: 'inFiboOfficeCloud – workplaces of developers and trade analysts.',
                howToSubscribe: 'HOW TO SUBSCRIBE',
                howToBuyLicense: 'HOW TO BUY A LICENSE',
                infiboSite: 'WEBSITE WWW.INFIBO.COM',
                review: 'Review',
                tradeHistory: 'Trade history',
                faqPage: 'FAQ page',
                onTheNextSlides: 'the following slides shows:',
                returnToInfibo: 'RETURN TO INFIBO.COM',
            }
        }
    },
    data: function () {
        return {
            revealInstance: null,
        }
    },
    computed: {
        currentLocale: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        changeLocale,
        goToSlide: function (number, relative = false) {
            this.revealInstance.slide(!relative ? number : this.revealInstance.getIndices().h + number)
        }
    },
    mounted() {
        this.revealInstance = new Reveal()
        this.revealInstance.initialize().then(() => this.revealInstance.sync())
    },
    beforeDestroy() {
        const html = document.querySelector('html')
        html.removeAttribute('class')
        const body = document.querySelector('body')
        body.removeAttribute('class')
        body.removeAttribute('style')
    }
}
</script>

<style scoped>
@import '~reveal.js/dist/reveal.css';
@import '~reveal.js/dist/theme/white.css';

.reveal .controls {
  color: #32a1ff;
}

.locale-switcher {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 5;
  cursor: pointer;
  font-size: 25px;
}

.reveal, .reveal h1, .reveal h2, .reveal h3, .reveal h4, .reveal h5, .reveal h6 {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  text-transform: none;
}

.reveal ul {
  margin: 0;
}

/* Intro, final */
.intro-slide {
  width: fit-content;
  margin: 0 auto 0 auto;
  text-align: right;
}

.intro-slide img {
  width: 800px;
}

.intro-slide p, .intro-slide a {
  color: white;
  font-size: 40px;
  margin: 0;
}

.intro-slide a:hover {
  color: #ccc;
}

/* What is inFibo */
.left-border {
  border-left: 7px solid #32a1ff;
  text-align: left;
  padding-left: 20px;
  font-size: 20px;
  margin-left: 45%;
  margin-top: 50px;
}

hr {
  background: #32a1ff;
  opacity: 1;
  height: 4px;
  margin: -15px auto 0 auto;
}

.screenshot-link:hover {
  cursor: pointer;
  color: #737373;
}

.screenshot-link:hover img {
  box-shadow: 2px 2px 7px 2px #d4d3d3;
}
</style>
